import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-circular-progress-bar',
  templateUrl: './circular-progress-bar.component.html',
  styleUrls: ['./circular-progress-bar.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class CircularProgressBarComponent {
  @Input() score: number | undefined;
  @Input() backgroundStroke: string = '#ce4d94c2';
  @Input() progressStroke: string = '#9D0759';
  @Input() textColor: string = '#9D0759';
  @Input() hideScore: boolean = true;
  radius: number = 40;
  circumference: number = 2 * Math.PI * this.radius;
  dashOffset: number = 0;

  ngOnInit(): void {
    this.updateProgress();
  }

  private updateProgress(): void {
    if (this.score) {
      this.dashOffset = this.circumference * (1 - (this.score / 5));
    }
  }
}